<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="plan-list" style="min-height: 100vh;" ref="listView">
            <div class="plan-item" v-for="(plan, index) in dataList" :key="index">
				<div class="plan-head">
					<span class="clinic-name" v-text="plan.name"></span>
					<span class="plan-hint">醫療計劃</span>
				</div>
				<div class="plan-details">
					<div class="plan-detail-w" v-for="(detail, i) in plan.values" :key="i">
						<div class="plan-detail" @click="showDetail(detail.medicalplanitemid)">
							<div class="row">
								<span class="plan-title" v-text="detail.servicename"></span>
								<span class="plan-count">{{detail.serviceqtyavailable}}次</span>
							</div>
							<div class="row">
								<span class="plan-time">{{detail.medicalplanstartdate}}-{{detail.medicalplanenddate}}</span>
								<span class="plan-count-hint">还剩</span>
							</div>
						</div>
						<plan-card-detail v-if="detailVisible" :id="detailId"/>
					</div>
				</div>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading"/>
	</van-pull-refresh>
</template>

<script>
	import plan_card from '@/views/mixins/plan_card'
	import Loading from '@/layouts/Loading.vue'
	import PlanCardDetail from './PlanCardDetail'
	export default {
		mixins: [
			plan_card
		],
		components: {
			Loading,
			PlanCardDetail
		},
		data(){
			return {
				loading: true,
				dataList: [],
				detailList: [],
				isRefresh: false,
				isLoadFinished: false,
				detailVisible: false,
				detailId: null,
			}
		},
		mounted: function(){
			this.loading = true
			this.onRefresh()
		},
		methods: {
			showDetail: function(id){
				if(this.detailId == id) {
					this.detailId = null
					this.detailVisible = false
				} else {
					this.detailId = id
					this.detailVisible = true
				}
			},
			onRefresh: function(){
				this.isLoadFinished = false
				this.loadData(this.$store.getters.clientId, (data, error)=>{
					if(error) {
						this.loading = false
						this._toast.warning(this, error)
						return
					}
					this.loading = false
					this.isRefresh = false
					this.dataList = data.list
					this.isLoadFinished = true
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.plan-list {
		display: flex;
		flex-direction: column;
		padding-top: 16px;
	}

	.plan-list > .plan-item {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 29px 24px 0px 24px;
	}

	.plan-list > .plan-item .plan-head {
		display: flex;
		flex-direction: row;
		height: 40px;
		align-items: center;
		justify-content: space-between;
	}

	.plan-list > .plan-item > .plan-head > .clinic-name {
		color: #1F1F1F;
		font-size: 34px;
		line-height: 33px;
		padding-left: 48px;
		position: relative;
	}

	.plan-list > .plan-item > .plan-head > .clinic-name::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		height: 28px;
		background-image: url(../../../../assets/imgs/mobile/icon_zhensuo@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.plan-list > .plan-item > .plan-head > .plan-hint {
		height: 40px;
		line-height: 40px;
		padding-left: 20px;
		padding-right: 19px;
		color: #fff;
		background-color: #1D68FF;
		border-radius: 20px;
		font-size: 24px;
		font-weight: 400;
	}

	.plan-list > .plan-item .plan-detail {
		height: 170px;
		background:linear-gradient(-14deg,rgba(95,148,255,1) 0%,rgba(173,225,255,1) 100%);
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		padding-left: 33px;
		padding-right: 48px;
		margin-top: 48px;
		margin-bottom: 12px;
	}

	.plan-list > .plan-item .plan-detail .row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 24px;
	}

	.plan-list > .plan-item .plan-detail .row:nth-child(1) {
		margin-top: 40px;
	}

	.plan-list > .plan-item .plan-detail .plan-count,
	.plan-list > .plan-item .plan-detail .plan-title {
		font-size: 34px;
		color: #fff;
		line-height: 32px;
		font-weight: bold;
	}

	.plan-list > .plan-item .plan-detail .plan-time,
	.plan-list > .plan-item .plan-detail .plan-count-hint {
		font-size: 28px;
		color: #fff;
		line-height: 26px;
		font-weight: 400;
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
		margin-top: 40px;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}
</style>
