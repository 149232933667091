<template>
	<div id="plan-card-detail">
		<div class="detail-loading" v-if="loading">
			<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			<span class="text">正在加载...</span>
		</div>
		<div class="statement">
			<div class="statement-item" v-for="(item, index) in dataList" :key="index">
				<div class="row">
					<span class="s-plan-time" v-text="item.registerdate"></span>
					<div class="s-doctor">
						<span :class="{'icon': true, 'man': item.sex==1}"></span>
						<span class="name" v-text="item.doctorname"></span>
					</div>
				</div>
				<div class="row">
					<span class="s-clinic-name" v-text="item.clinicname"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import plan_card from '@/views/mixins/plan_card'
	export default {
		props: {
			id: null,
		},
		mixins: [
			plan_card
		],
		components: {
		},
		data(){
			return {
				loading: true,
				dataList: [],
			}
		},
		mounted: function(){
			this.loading = true
			this.getDetail(this.$store.getters.clientId, this.id, (data, error)=>{
				if(error) {
					this.loading = false
					this._toast.warning(this, error)
					return
				}
				this.loading = false
				this.dataList = data.list
			})
		},
	}
</script>

<style lang="scss" scoped>
	.statement {
		display: flex;
		flex-direction: column;
		padding: 40px 16px;
	}

	.statement .statement-item {
		display: flex;
		flex-direction: column;
		margin-top: 48px;
	}

	.statement .statement-item:nth-child(1) {
		margin-top: 0px;
	}

	.statement .statement-item .row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.statement .statement-item .row:nth-child(2) {
		margin-top: 17px;
	}

	.statement .statement-item .s-doctor,
	.statement .statement-item .s-plan-time {
		color: #1F1F1F;
		font-size: 36px;
		font-weight: bold;
		height: 31px;
		line-height: 31px;
	}

	.statement .statement-item .s-doctor {
		position: relative;
		padding-left: 46px;
	}

	.statement .statement-item .s-doctor > .icon {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-image: url(../../../../assets/imgs/mobile/user_center/img_nvys@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.statement .statement-item .s-doctor > .icon.man {
		background-image: url(../../../../assets/imgs/mobile/user_center/img_nanys@2x.png);
	}

	.statement .statement-item .s-clinic-name {
		font-size: 24px;
		color: #999999;
		font-weight: 400;
	}

	// detail-loading start
	.detail-loading {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding-top: 40px;
		box-sizing: border-box;
	}

	.detail-loading > .text {
		margin-left: 24px;
		font-size: 32px;
		font-weight: 400;
		color: #999999;
	}

	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
	//detail-loading end
</style>
